@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

body {
    font-family: 'Lato', sans-serif;
    text-align: center;
    background: #2994fe;
    color: #ecf0f1;
}

h1 {
    font-size: 50px;
    text-shadow: 0 5px #000;
}

#button {
    height: 160px;
    width: 300px;
    margin: 0 auto;
}

#button div {
    position: absolute;
    transition: all .05s;
}

#floor {
    height: 50px;
    width: 300px;
    background: #34495e;
    border-radius: 50%;
    margin-top: 80px;
    z-index: -1;
}

#top {
    background: #e74c3c;
    height: 40px;
    width: 250px;
    border-radius: 50%;
    z-index: 1;
    margin-left: 25px;
}

#bottom {
    background: #c0392b;
    height: 40px;
    width: 250px;
    border-radius: 50%;
    margin-top: 80px;
    margin-left: 25px;
}

#body {
    background: #c0392b;
    width: 250px;
    border-radius: 0;
    margin-left: 25px;
    height: 80px;
    margin-top: 20px;
}

#button:hover {
    cursor: pointer;
}

.top-click {
    margin-top: 40px;
}

.body-click {
    height: 40px !important;
    margin-top: 60px !important;
}

#button:hover #floor {
    background: #95a5a6;
}